import { Tooltip } from 'antd';
import { getDetailHelperInfo } from './getDetailHelperInfo';
import { RouterOutputs } from '../../../utils/trpc';

export const MarkdownTitle = (detail: RouterOutputs['note']['detail']) => {
	return (
		<Tooltip title={<pre>{getDetailHelperInfo(detail)}</pre>}>
			{detail.title}
		</Tooltip>
	);
};
