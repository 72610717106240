import { default as toc } from '@jsdevtools/rehype-toc';
import type { PluggableList } from 'unified';
import { default as rehypeSlug } from 'rehype-slug';
import remarkGfm from 'remark-gfm';

const remarkPluginConfig = {
	remarkPlugins: [remarkGfm] as PluggableList,
	rehypePlugins: [rehypeSlug, toc] as PluggableList,
};

export default remarkPluginConfig;
