import { emptyString } from '../../../utils/constant';
import { timestampToString } from '../../../utils/timeUtil';
import type { RouterOutputs } from '../../../utils/trpc';

export function getDetailHelperInfo(
	detail: RouterOutputs['note']['detail'],
): React.ReactNode {
	return [
		[
			'updatedAt',
			detail.updatedAt ? timestampToString(detail.updatedAt) : emptyString,
		],
		['version', detail.version],
		['createdAt', timestampToString(detail.createdAt)],
	]
		.map((i) => i.join(': '))
		.join('\n');
}
