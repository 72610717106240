export const requestBaseUrl = () =>
	"production" === 'development'
		? 'http://127.0.0.1:18888'
		: 'https://api.ihook.center';

export const GA_TRACKING_ID = 'G-3QZJ07BNQH';
export const REQUEST_BASE_URL = 'https://api.ihook.center';
export const DEV_REQUEST_BASE_URL = 'https://api.ihook.center';
export const V_QUEST_BASE_URL = 'http://localhost:18889';

export const emptyString = '-';
