import { message } from 'antd';
import style from '../../../styles/Markdown.module.css';
// (see https://github.com/highlightjs/highlight.js/tree/main/src/languages)
import javascript from 'highlight.js/lib/languages/javascript';
import shell from 'highlight.js/lib/languages/shell';
import typescript from 'highlight.js/lib/languages/typescript';
import 'highlight.js/styles/default.css';
// import Markdown from 'marked-react';
// import 'node_modules/@primer/css/dist/markdown.css';
import LowLight from 'react-lowlight';
// Then register them with lowlight
LowLight.registerLanguage('javascript', javascript);
LowLight.registerLanguage('typescript', typescript);
LowLight.registerLanguage('shell', shell);
// import 'react-markdown-navbar/dist/style.css';
import ReactMarkdown from 'react-markdown';
// import MarkNav from 'react-markdown-navbar';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { MarkdownTitle } from './MarkdownTitle';

import remarkPluginConfig from './remarkPluginConfig';
import { RouterOutputs } from '../../../utils/trpc';

export const MarkdownRender = ({
	detail,
	lightWord = [],
	isHasTitle = true,
}: {
	detail: RouterOutputs['note']['detail'];
	lightWord?: string[];
	isHasTitle?: boolean;
}) => {
	document.title = detail.title ?? '笔记';

	return (
		<>
			{isHasTitle && detail.title !== detail.content && MarkdownTitle(detail)}

			{detail?.content && (
				<ReactMarkdown
					className={style['markdown-body']}
					remarkPlugins={remarkPluginConfig.remarkPlugins}
					rehypePlugins={remarkPluginConfig.rehypePlugins}
					components={{
						code({ node, inline, className, children, ...props }) {
							const match = /language-(\w+)/.exec(className || '');
							const snippet = String(children).replace(/\n$/, '');

							return (
								<div
									key={snippet}
									className={style.pre}
									style={{ position: 'relative' }}
								>
									{!inline && match ? (
										<SyntaxHighlighter
											// @ts-expect-error type error
											style={prism}
											language={match[1]}
											{...props}
										>
											{snippet}
										</SyntaxHighlighter>
									) : (
										<LowLight language={match?.[1]} value={snippet} />
									)}

									{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
									<div
										className={style.copyThis}
										onClick={async () => {
											await navigator.clipboard.writeText(snippet);
											message.info('粘贴成功');
										}}
									>
										copy
									</div>
								</div>
							);
						},
						// p({ children }: { children: (string | React.ReactNode)[] }) {
						//   return (
						//     <p>
						//       {children.flatMap((i) => {
						//         if (typeof i !== 'string') {
						//           return i;
						//         }

						//         const pickContent = i?.match(
						//           new RegExp(`(.*)(${lightWord?.join('|')})(.*)`),
						//         );

						//         if ((pickContent?.length ?? 0) > 0) {
						//           return [
						//             <div key={textStyle.text} className={textStyle.text}>
						//               {pickContent?.[1]}
						//             </div>,
						//             <div
						//               key={textStyle.text}
						//               className={`${textStyle.text} ${textStyle['key-highLight']}`}
						//             >
						//               {pickContent?.[2]}
						//             </div>,

						//             <div key={textStyle.text} className={textStyle.text}>
						//               {pickContent?.[3]}
						//             </div>,
						//           ];
						//         }

						//         return i;
						//       })}
						//     </p>
						//   );
						// },
					}}
				>
					{detail.content}
				</ReactMarkdown>
			)}
		</>
	);
};
